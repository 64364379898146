import { isRegisteredUserRoute } from './authcheck';
import EngineersList from '../containers/gigUser/EngineersList/EngineersList';
import EngineerDetailView from '../containers/profile/engineer/GigAppliedProfile/GigAppliedEngineer';
import OwnerDetails from '../containers/admin/gigOwner/ownerDetails/ownerDetails';
import AllGigs from '../containers/admin/gigOwner/ownerDetails/allGigs/allGigs';
import OwnerList from '../containers/admin/gigOwner/OwnerList/OwnerList';
import GigDetailsP from "../containers/gigUser/gigdetails/GigDetailsP";
import AllGigsAdmin from '../containers/admin/gigOwner/ownerDetails/allGigs/AllGigsAdmin';
import EngineerProfile from '../containers/gigUser/EngineersList/EngineerProfile';
import BusinessProfile from '../containers/gigUser/EngineersList/BusinessProfile';
import Reporting from '../containers/admin/Reporting';
import InvoiceConsolidated from './../containers/invoices/consolidated/index';
import InvoiceDetails from '../containers/invoices/details/index';
import ViewFeedbackAdmin from '../containers/reviews/adminSide/ViewFeedbackAdmin';
import viewBankDetails from '../containers/profile/engineer/BankDetails/viewBankDetails';

const adminRoutes = [
  {
    path: '/admin/engineer/list',
    component: isRegisteredUserRoute(EngineersList, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/engineer/profile',
    component: isRegisteredUserRoute(EngineerProfile, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/engineer/profile/viewBankDetails',
    component: isRegisteredUserRoute(viewBankDetails, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/engineer/profile/:id',
    component: isRegisteredUserRoute(EngineerDetailView, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/business/profile',
    component: isRegisteredUserRoute(BusinessProfile, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/business/profile/list',
    component: isRegisteredUserRoute(EngineersList, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/engineer/details/:id',
    component: isRegisteredUserRoute(EngineerDetailView, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/owner/allgigs/details',
    component: isRegisteredUserRoute(AllGigsAdmin, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/owner/allgigs/details/:id',
    component: isRegisteredUserRoute(GigDetailsP, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/business/details/:id',
    component: isRegisteredUserRoute(OwnerDetails, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/owner/list',
    component: isRegisteredUserRoute(OwnerList, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/owner/details/:id',
    component: isRegisteredUserRoute(OwnerDetails, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/owner/details/:id/allgigs',
    component: isRegisteredUserRoute(AllGigs, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/owner/details/:id/allgigs/gigdetails',
    component: isRegisteredUserRoute(GigDetailsP, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/reporting',
    component: isRegisteredUserRoute(Reporting, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/invoices',
    component: isRegisteredUserRoute(InvoiceConsolidated, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/admin/invoices/:id',
    component: isRegisteredUserRoute(InvoiceDetails, 'admin', 'admin'),
    exact: true
  },
  {
    path: '/gig/review/admin/viewFeedback/:id',
    component: isRegisteredUserRoute(ViewFeedbackAdmin, 'admin', 'admin'),
    exact: true
  },
];

export default adminRoutes;
