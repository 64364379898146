import React, { useEffect, useState } from 'react';
import Header from './Header';
import { useHistory } from 'react-router-dom';
import './mainPage.css';
import FooterAfterSignIn from '../footer1/FooterAfterSignIn';
import Button from '@mui/material/Button';
import { getUserType } from '../../utils/user';
import FindGigPopup from './FindGigPopup';
import FindEngineerPopup from './FindEngineerPopup';
import './mainNewPage.css';
import HomePage1 from '../../assests/image/homePage1.png'
import HomePage2 from '../../assests/image/homepage2.png'
import HomePage3 from '../../assests/image/homepage3.png'
import { Grid, Link, TextField } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Slider from "react-slick";
import Business1 from '../../assests/image/business1.svg'
import Business2 from '../../assests/image/business2.svg'
import Business3 from '../../assests/image/business3.svg'
import Engineer1 from '../../assests/image/engineer1.svg'
import Engineer2 from '../../assests/image/engineer2.svg'
import Engineer3 from '../../assests/image/engineer3.svg'
import Pocess1 from '../../assests/image/process1.svg'
import Pocess2 from '../../assests/image/process2.svg'
import Pocess3 from '../../assests/image/process3.svg'
import Pocess4 from '../../assests/image/process4.svg'
import Pocess5 from '../../assests/image/process5.svg'
import Pocess6 from '../../assests/image/process6.svg'
import Pocess7 from '../../assests/image/process7.svg'
import whyUs1 from '../../assests/image/whyUs1.svg'
import whyUs2 from '../../assests/image/whyUs2.svg'
import whyUs3 from '../../assests/image/whyUs3.svg'
import whyUs4 from '../../assests/image/whyUs4.svg'
import SignUpImage from '../../assests/image/signUpImage.svg'
import Newimg from '../../assests/image/newImg.svg'
import MailIcon from '../../assests/image/mailIcon.svg'
import Terminal1 from '../../assests/image/terminal1.svg'
import Terminal2 from '../../assests/image/terminal2.svg'
import Terminal3 from '../../assests/image/terminal3.svg'
import Terminal4 from '../../assests/image/terminal4.svg'
import AGig from '../../assests/image/logo/firstLogo.png'
import CGig from '../../assests/image/logo/secondLogo.png'
import EGig from '../../assests/image/logo/thirdLogo.png'
import FGig from '../../assests/image/logo/forthLogo.png'
import FuGig from '../../assests/image/logo/fiveLogo.png'
import HGig from '../../assests/image/logo/sixLogo.png'
import MGig from '../../assests/image/logo/sevenLogo.png'
import NGig from '../../assests/image/logo/eightLogo.png'
import PGig from '../../assests/image/logo/nineLogo.png'
import RGig from '../../assests/image/logo/tenLogo.png'
import SGig from '../../assests/image/logo/logoGig1.png'
import SnGig from '../../assests/image/logo/logoGig2.png'
import TGig from '../../assests/image/logo/logoGig3.png'
import ZGig from '../../assests/image/logo/logoGig4.png'
import { QueryContactUs, postUserCollection } from '../../services/gig';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Engineere1 from '../../assests/image/logo/engineere1.png'
import Engineere2 from '../../assests/image/logo/engineere2.png'
import Engineere3 from '../../assests/image/logo/engineere3.png'
import Engineer4 from '../../assests/image/logo/engineer4.png'
import Engineer5 from '../../assests/image/logo/engineer5.png'
import Engineer6 from '../../assests/image/logo/engineer6.png'
import Engineer7 from '../../assests/image/logo/engineer7.png'
import Engineer8 from '../../assests/image/logo/engineer8.png'
import Engineer9 from '../../assests/image/logo/engineer9.png'
import Engineer10 from '../../assests/image/logo/engineer10.png'
import RightArrow from '../../assests/image/rightArrow.svg'
import LeftArrow from '../../assests/image/leftArrow.svg'
import Line1 from '../../assests/image/logo/Line1.svg'
import Line2 from '../../assests/image/logo/Line2.svg'
import SignUpImage1 from '../../assests/image/background-signup-img.svg'
import { toast } from 'react-toastify';
import Marquee from 'react-fast-marquee';
import Engineer11 from '../../assests/image/logo/engineer11.png'
import Engineer12 from '../../assests/image/logo/engineer12.png'
import Engineer13 from '../../assests/image/logo/engineer13.png'
import ChikeImg from '../../assests/image/ChikeImg.png'
import ShawnImg from '../../assests/image/shawnImg.png'
import SeelanImg from '../../assests/image/SeelanImg.png'
import RashmaImg from '../../assests/image/RashmaImg.jpg'

const Timelines = [
  {
    label: 'Market Research',
    description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`
  },
  {
    label: 'Market Research',
    description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`
  },
  {
    label: 'Market Research',
    description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`
  }
];
let flag = '';
const HomePage = () => {
  const history = useHistory();
  const userType = getUserType();
  const [open, setOpen] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openForgetEngineer, setOpenForgetEngineer] = React.useState(false);
  const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
  const [openBusinessModal, setOpenBusinessModal] = React.useState(false);
  const [openForgetBusiness, setopenForgetBusiness] = React.useState(false);
  const [email, setEmail] = useState('');
  const [emailMsg, setEmailMsg] = useState('');
  const [query, setQuery] = useState("");
  const [queryMsg, setQueryMsg] = useState("");
  const [first, setFirst] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [last, setLast] = useState("");
  const [lastMsg, setLastMsg] = useState("");

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const [emailG, setEmailG] = useState('');
  const [emailMsgG, setEmailMsgG] = useState('');

  const submitFormM = async () => {
    let validate = true;

    if (emailG === '' || emailG === null) {
      validate = false;
      setEmailMsgG('Please enter the email!');
    } else {
      let isEmailValid = isValidEmail(emailG);
      if (isEmailValid) {
        setEmailMsgG('');
      } else {
        validate = false;
        setEmailMsgG('Enter Valid email!');
      }
    }

    if (validate) {
      let data = {
        email: emailG
      };

      postUserCollection(data).then((e) => {
        setEmailG('');
        setEmailMsgG('');
        toast.success("successfully!");
      })
    }
  };

  const submitForm = async () => {
    let validate = true;
    if (first === "" || first === null) {
      validate = false;
      setFirstMsg("Please enter the First name!");
    } else {
      setFirstMsg("");
    }
    if (last === "" || last === null) {
      validate = false;
      setLastMsg("Please enter the Last name!");
    } else {
      setLastMsg("");
    }
    if (email === '' || email === null) {
      validate = false;
      setEmailMsg('Please enter the email!');
    } else {
      let isEmailValid = isValidEmail(email);
      if (isEmailValid) {
        setEmailMsg('');
      } else {
        validate = false;
        setEmailMsg('Enter Valid email!');
      }
    }

    if (query === "" || query === null) {
      validate = false;
      setQueryMsg("Please enter the your message!");
    } else {
      setQueryMsg("");
    }

    if (validate) {
      let data = {
        firstname: first,
        lastname: last,
        emailId: email,
        description: query,
      }

      QueryContactUs(data).then((e) => {
        setFirst("");
        setLast("");
        setEmail("");
        setQuery("");
        toast.success("successfully!");
      })
    }
  };

  const [isBusiness, setIsBusiness] = useState(true);
  const [activeClass, setActiveClass] = useState('');

  const toggleCardType = () => {
    setActiveClass('active');

    setTimeout(() => {
      setIsBusiness(!isBusiness);
      setActiveClass('');
    }, 250);
  };

  const [isWorkBusiness, setIsWorkBusiness] = useState(true);

  const toggleWorkCardType = () => {
    setActiveClass('active');

    setTimeout(() => {
      setIsWorkBusiness(!isWorkBusiness);
      setActiveClass('');
    }, 250);
  };

  const modalEngineerSignUpOpen = () => {
    setOpenSignUp(true);
  }

  const businessModal = () => {
    setOpenBusinessModal(true);
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  useEffect(() => {
    const accordionItemHeaders = document.querySelectorAll(".accordion-item-header");

    accordionItemHeaders.forEach(accordionItemHeader => {
      accordionItemHeader.addEventListener("click", event => {
        accordionItemHeader.classList.toggle("active");
        const accordionItemBody = accordionItemHeader.nextElementSibling;
        if (accordionItemHeader.classList.contains("active")) {
          accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
        }
        else {
          accordionItemBody.style.maxHeight = 0;
        }

      });
    });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "White", color: 'white', top: '75px', right: '-50px' }}
        onClick={onClick}
      >
        <img src={RightArrow} alt="RightArrow" fontSize="medium" width="60%" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "White", color: 'white', top: '75px' }}
        onClick={onClick}
      >
        <img src={LeftArrow} alt="LeftArrow" fontSize="medium" width="60%" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settingsTest = {
    dots: false,
    infinite: true,
    centerMode: true,  // Enable center mode
    centerPadding: '0px',  // Set padding to 0 to ensure cards are truly centered
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          initialSlide: 2
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  function scrollDirect(uniqueId) {
    document.getElementById(uniqueId).scrollDirect();
  }

  return (
    <React.Fragment>
      <Header />
      <div className="main-home-page-container">
        <div className='div1-container'>
          <div className='d1-component'>
            <h1>Engineering's new secret weapon.</h1>
            <p>Looking for the A-team on your next project? Unleash
              your engineering superpowers with us. Join forces with
              our global alliance of brilliant engineers to create
              something truly extraordinary on your next project. </p>
            <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', width: '280px', maxWidth: '280px' }}>
              <Button
                variant="contained"
                className="discover-btn-homepage"
                type="submit"
                sx={{
                  textTransform: 'none',
                  width: '120px',
                  height: '40px',
                  boxShadow: 'none',
                  backgroundColor: '#003FAA'
                }}
                onClick={modalEngineerSignUpOpen}
              >
                Discover Gigs
              </Button>
              <Link
                className="card-Link"
                component="button"
                variant="body2"
                sx={{ textDecoration: "none", color: '#1C83E5', fontSize: '14px', fontWeight: '500', display: 'flex', marginLeft: '15px', marginTop: '13px' }}
                onClick={businessModal}
              >
                Find Engineers <ArrowRightAltIcon />
              </Link>
            </div>
            <div className='Clients-Endorsers-container-homepage'>
              <div className='Clients-Endorsers'>
                Proud Clients & Endorsers
              </div>
              <Marquee pauseOnHover="false" speed={40}>
                <img className='marquee-img' src={AGig} alt="AGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={CGig} alt="CGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={EGig} alt="EGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={FGig} alt="FGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={FuGig} alt="FuGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={HGig} alt="HGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={MGig} alt="MGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={NGig} alt="NGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={PGig} alt="PGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={RGig} alt="RGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={SGig} alt="SGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={SnGig} alt="SnGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={TGig} alt="tGig" fontSize="medium" width="55%" />
                <img className='marquee-img' src={ZGig} alt="zGig" fontSize="medium" width="55%" />
              </Marquee>
            </div>
          </div>
          <div>
          </div>
          <div className='main-page-image-1'>
            <img className='homePage1-img' src={HomePage1} alt="HomePage1" fontSize="medium" width="100%" />
          </div>
        </div>

        <div className='member-benefit'>
          Member Benefits
        </div>

        <div className='switch-btn-homepage'>
          <div className='engineer-text-homepage'>
            ENGINEER
          </div>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch"
              checked={isBusiness}
              onChange={toggleCardType}
            />
            <label className="onoffswitch-label" htmlFor="myonoffswitch">
              <span className="onoffswitch-inner"></span>
              <span className="onoffswitch-switch"></span>
            </label>
          </div>
          <div className='business-text-homepage'>
            BUSINESS
          </div>
        </div>

        {isBusiness ?
          <div className={`business-card-container ${isBusiness ? activeClass : 'active'}`}>
            <div className='business-card-1'>
              <div className='img-number-1'>
                <div>
                  <div>
                    <img className='engineer-and-business-img' src={Business1} alt="Business1" fontSize="medium" width="50%" />
                  </div>
                </div>
                <div className='business-01'>
                  01
                </div>
              </div>
              <p className='paragraph-business-1'>
                Credible Engineers
              </p>
              <h1 className='text-business-1'>
                Unlock a league of elite engineers
                spanning diverse disciplines & skillsets.
              </h1>
            </div>

            <div className='business-card-2'>
              <div className='img-number-2'>
                <div>
                  <div>
                    <img className='engineer-and-business-img' src={Business2} alt="Business2" fontSize="medium" width="50%" />
                  </div>
                </div>
                <div className='business-02'>
                  02
                </div>
              </div>
              <p className='paragraph-business-2'>
                Engineering On-Demand
              </p>
              <h1 className='text-business-2'>
                Effortlessly find the best engineers
                for your project in 1-2-click.
              </h1>
            </div>

            <div className='business-card-3'>
              <div className='img-number-3'>
                <div>
                  <div>
                    <img className='engineer-and-business-img' src={Business3} alt="Business3" fontSize="medium" width="50%" />
                  </div>
                </div>
                <div className='business-03'>
                  03
                </div>
              </div>
              <p className='paragraph-business-3'>
                Expand Your Reach
              </p>
              <h1 className='text-business-3'>
                Magnify your influence and engage
                with a thriving global community.
              </h1>
            </div>
          </div>
          :
          <div className={`engineer-card-container ${!isBusiness ? activeClass : 'active'}`}>
            <div className='engineer-card-1'>
              <div className='img-number-1'>
                <div>
                  <div>
                    <img className='engineer-and-business-img' src={Engineer1} alt="Engineer1" fontSize="medium" width="50%" />
                  </div>
                </div>
                <div className='engineer-01'>
                  01
                </div>
              </div>
              <p className='paragraph-engineer-1'>
                Efficient Execution
              </p>
              <h1 className='text-engineer-1'>
                Utilise streamlined processes to
                optimise project execution &
                getting paid.
              </h1>
            </div>

            <div className='engineer-card-2'>
              <div className='img-number-2'>
                <div>
                  <div>
                    <img className='engineer-and-business-img' src={Engineer2} alt="Engineer2" fontSize="medium" width="50%" />
                  </div>
                </div>
                <div className='engineer-02'>
                  02
                </div>
              </div>
              <p className='paragraph-engineer-2'>
                Work-Life Balance
              </p>
              <h1 className='text-engineer-2'>
                Flexible project options and
                remote work opportunities.
              </h1>
            </div>

            <div className='engineer-card-3'>
              <div className='img-number-3'>
                <div>
                  <div>
                    <img className='engineer-and-business-img' src={Engineer3} alt="Engineer3" fontSize="medium" width="50%" />
                  </div>
                </div>
                <div className='engineer-03'>
                  03
                </div>
              </div>
              <p className='paragraph-engineer-3'>
                Skill Enhancement
              </p>
              <h1 className='text-engineer-3'>
                Sharpen your expertise by engaging
                in innovative projects from across
                the globe.
              </h1>
            </div>
          </div>
        }

        <div className='how-we-work-container' id='Synergy-Driven'>
          <div className='how-we-work-text-container'>
            <h1 className='how-we-work-text-heading'>
              Our Synergy-Driven Story
            </h1>
            <p className='how-we-work-text-paragraph'>
              At Gig Engineer, we're passionate about revolutionising the
              conventional approach to resourcing engineering work. By bridging
              the gap between business needs and precise project-based
              expertise, we not only enable organisational agility and efficiency, but
              also empower engineers with autonomy and the flexibility to choose
              projects that ignite their passions - paving the way for the ideal
              work-life balance. Conceived and crafted by visionary engineers, we
              invite you to join us in shaping the future of engineering work.
            </p>
            <Button
              variant="contained"
              className="how-we-homepage"
              type="submit"
              sx={{
                textTransform: 'none',
                width: '120px',
                height: '40px',
                boxShadow: 'none',
                backgroundColor: '#003FAA'
              }}
              onClick={() => { window.location.href = "#how-we-work-id" }}
            >
              How We Work
            </Button>
          </div>
          <div className='homepage-img-2'>
            <img src={HomePage2} alt="HomePage2" fontSize="medium" width="80%" />
          </div>
        </div>

        <div className='how-we-work-container-2'>
          <div className='home-page-img-3'>
            <img src={HomePage3} alt="HomePage3" fontSize="medium" width="70%" />
          </div>
          <div className='innovation-heading'>
            <h1 className='how-we-work-text-heading'>
              Innovation at the Intersection
            </h1>
            <p className='how-we-work-text-paragraph'>
              Imagine a world where the brightest minds seamlessly connect and
              collaborate to create a more sustainable and prosperous future.
              We are committed to turning that vision into a reality. Our
              community of dedicated professionals thrives on innovation and
              solving complex engineering challenges. Join us today and let's
              bring your projects to life!
            </p>
            <Button
              variant="contained"
              className="Register-homepage"
              type="submit"
              sx={{
                textTransform: 'none',
                width: '120px',
                height: '40px',
                boxShadow: 'none',
                backgroundColor: '#003FAA'
              }}
              onClick={() => {
                history.push(`/signup`);
              }}
            >
              Register
            </Button>
          </div>
        </div>

        <div className='our-process-heading'>
          Our Process
        </div>

        <div className='how-we-work-switch-btn' id='how-we-work-id'>
          How We Work
        </div>

        <div className='switch-btn-homepage'>
          <div className='engineer-text-homepage'>
            ENGINEER
          </div>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch-checkbox"
              id="myonoffswitch2"
              checked={isWorkBusiness}
              onChange={toggleWorkCardType}
            />
            <label className="onoffswitch-label" htmlFor="myonoffswitch2">
              <span className="onoffswitch-inner"></span>
              <span className="onoffswitch-switch"></span>
            </label>
          </div>
          <div className='business-text-homepage'>
            BUSINESS
          </div>
        </div>

        {isWorkBusiness ?
          <>
            <div className={`toggle-2-card-container ${isWorkBusiness ? activeClass : 'active'}`}>
              <div className='line-1-img'>
                <img className='line-1-img-work' src={Line2} alt="Line2" fontSize="medium" width="100%" />
                <img className='line-2-img-work' src={Line1} alt="Line1" fontSize="medium" width="100%" />
              </div>
              <div className='how-we-work-business-container'>
                <div className='how-we-work-business-card-1'>
                  <div className='how-we-work-img-1-b'>
                    <img src={Pocess1} alt="Pocess1" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-business-1'>
                    01. Join Us
                  </div>
                  <div className='how-we-work-business-para-1'>
                    Create your business profile for free and effortlessly post an engineering job.
                  </div>
                </div>
                <div className='how-we-work-business-card-2'>
                  <div className='how-we-work-img-2-b'>
                    <img src={Pocess2} alt="Pocess2" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-business-2'>
                    02. Search
                  </div>
                  <div className='how-we-work-business-para-2'>
                    Discover the perfect match for
                    your project. Browse or use our
                    advanced search tools.
                  </div>
                </div>
                <div className='how-we-work-business-card-3'>
                  <div className='how-we-work-img-3-b'>
                    <img src={Pocess3} alt="Pocess3" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-business-3'>
                    03. Connect
                  </div>
                  <div className='how-we-work-business-para-3'>
                    Connect directly with the best
                    candidates for the job. Use our
                    platform to discuss and finalise
                    work-specific requirements.
                  </div>
                </div>
                <div className='how-we-work-business-card-4'>
                  <div className='how-we-work-img-4-b'>
                    <img src={Pocess4} alt="Pocess4" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-business-4'>
                    04. Hire & Pay
                  </div>
                  <div className='how-we-work-business-para-4'>
                    Once both parties agree on the
                    terms of the gig, easily hire and
                    securely pay for the work.
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <>
            <div className={`toggle-2-card-container ${!isWorkBusiness ? activeClass : 'active'}`}>
              <div className='line-1-img'>
                <img className='line-1-img-work' src={Line2} alt="Line2" fontSize="medium" width="100%" />
                <img className='line-2-img-work' src={Line1} alt="Line1" fontSize="medium" width="100%" />
              </div>
              <div className='how-we-work-engineer-container'>
                <div className='how-we-work-engineer-card-1'>
                  <div className='how-we-work-img-1-e'>
                    <img src={Pocess1} alt="Pocess1" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-engineer-1'>
                    01. Sign Up
                  </div>
                  <div className='how-we-work-engineer-para-1'>
                    Sign up and create your
                    professional profile with no
                    obligation or sign-up fees.
                  </div>
                </div>
                <div className='how-we-work-engineer-card-2'>
                  <div className='how-we-work-img-2-e'>
                    <img src={Pocess5} alt="Pocess5" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-engineer-2'>
                    02. Find a Gig
                  </div>
                  <div className='how-we-work-engineer-para-2'>
                    Find gigs that match your skills,
                    either by applying or getting
                    directly engaged by businesses.
                  </div>
                </div>
                <div className='how-we-work-engineer-card-3'>
                  <div className='how-we-work-img-3-e'>
                    <img src={Pocess6} alt="Pocess6" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-engineer-3'>
                    03. Get Paid
                  </div>
                  <div className='how-we-work-engineer-para-3'>
                    Get paid conveniently by
                    generating and submitting invoices
                    seamlessly within our platform.
                  </div>
                </div>
                <div className='how-we-work-engineer-card-4'>
                  <div className='how-we-work-img-4-e'>
                    <img src={Pocess7} alt="Pocess7" fontSize="medium" width="55%" />
                  </div>
                  <div className='how-we-work-engineer-4'>
                    04. Get Feedback
                  </div>
                  <div className='how-we-work-engineer-para-4'>
                    Receive valuable feedback and
                    referrals from satisfied clients,
                    building your professional network.
                  </div>
                </div>
              </div>
            </div>
          </>
        }

       <div style={{paddingTop: '40px'}}  id='Whyus-Wrapper'>
        <div className='why-us-container'>

          <div className='why-us-heading'>
            Why Us?
          </div>
          <div className='why-us-text'>
            Connecting engineers and businesses worldwide for agile
            collaboration and limitless possibilities.
          </div>

          <div className='why-us-card-container'>
            <div className='why-us-card-1'>
              <div className='why-us-card-1-img'>
                <img src={whyUs1} alt="whyUs1" fontSize="medium" width="8%" />
              </div>
              <h1 className='why-us-card-1-heading'>
                Trusted Partners
              </h1>
              <p className='why-us-card-1-para'>
                Trust and safety are our top priorities. We
                understand your business since we're engineers
                ourselves. We build long term relationships with
                our users to achieve business and career goals,
                not just tasks.
              </p>
            </div>
            <div className='why-us-card-2'>
              <div className='why-us-card-2-img'>
                <img src={whyUs2} alt="whyUs2" fontSize="medium" width="10%" />
              </div>
              <h1 className='why-us-card-2-heading'>
                Easy Invoicing & Payment
              </h1>
              <p className='why-us-card-2-para'>
                Say goodbye to messy spreadsheets and endless
                paperwork. With Gig Engineer's invoicing feature, you
                can easily create, manage, and track invoices,
                ensuring timely payment for your hard work.
              </p>
            </div>
          </div>

          <div className='why-us-card-container'>
            <div className='why-us-card-3'>
              <div className='why-us-card-3-img'>
                <img src={whyUs3} alt="whyUs3" fontSize="medium" width="8%" />
              </div>
              <h1 className='why-us-card-3-heading'>
                Advanced Matching
              </h1>
              <p className='why-us-card-3-para'>
                We understand finding the right gig or the right talent
                can be overwhelming and time consuming. We
                provide an advanced search engine to easily find the
                perfect match, saving you time and money so you can
                focus on what matters: Execution!
              </p>
            </div>
            <div className='why-us-card-4'>
              <div className='why-us-card-4-img'>
                <img src={whyUs4} alt="whyUs4" fontSize="medium" width="8%" />
              </div>
              <h1 className='why-us-card-4-heading'>
                Pipeline Management
              </h1>
              <p className='why-us-card-4-para'>
                Need a resource for a proposal? We allow businesses
                to easily create jobs for any stage of the project
                pipeline based on their short- or long- term
                resourcing needs. Our dashboards keep all your
                resources or gigs organised in one place so you can
                stay on top of deadlines, milestones, and
                communications with ease.
              </p>
            </div>
          </div>
        </div>
        </div>

        <div className='meet-our-register-btn'>
          <h1 className='meet-our-heading'>
            Meet Our Gig Engineers
          </h1>
          <div>
            <a className='register-btn-link' href='/signup'>
              Register Now
            </a>
          </div>
        </div>

        <div className='gig-peoples-container'>
          <div className="slider-container">
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
            />
            <Slider {...settings}>
            <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://www.gigengineer.io/profile/pascal ">
              <div className='gig-peoples-1-container'>
                <img src={Engineere1} alt="Engineere1" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Pascal Sanchez Martin
                  </h1>
                <p className='people-1-para'>
                 Engineering Manager / Senior Mechanical Engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://www.gigengineer.io/profile/6530ea2b44e3e135be40370b  ">
              <div className='gig-peoples-1-container'>
                <img src={Engineere3} alt="Engineere3" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Dipesh Dhaya
                  </h1>
                <p className='people-1-para'>
                  Professional Architectural Technologist
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://www.gigengineer.io/profile/635ff0d92929c93d437b3d52  ">
              <div className='gig-peoples-1-container'>
                <img src={Engineer4} alt="Engineer4" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Graham Bainbridge
                  </h1>
                <p className='people-1-para'>
                 Mechanical engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href=" https://www.gigengineer.io/profile/Kerusha  ">
              <div className='gig-peoples-1-container'>
                <img src={Engineer5} alt="Engineer5" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Kerusha Anandram
                  </h1>
                <p className='people-1-para'>
                Professional Civil Engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href=" https://www.gigengineer.io/profile/karabon  ">
              <div className='gig-peoples-1-container'>
                <img src={Engineer7} alt="Engineer7" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Karabo Nthethe
                  </h1>
                <p className='people-1-para'>
                Senior Process Engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://www.gigengineer.io/profile/hadaskt  ">
              <div className='gig-peoples-1-container'>
                <img src={Engineer8} alt="Engineer8" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Hadas Tewelde
                  </h1>
                <p className='people-1-para'>
                Civil and Structural Tech Engineering
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://gigengineer.io/profile/christian-ngoy">
              <div className='gig-peoples-1-container'>
                <img src={Engineer9} alt="Engineer9" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Christian Ngoy
                  </h1>
                <p className='people-1-para'>
                Mechanical Design Engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://www.gigengineer.io/profile/651beea944e3e117a04019d5">
              <div className='gig-peoples-1-container'>
                <img src={Engineer10} alt="Engineer10" fontSize="medium" width="100%" />
                  <h1 className='people-1-name'>
                    Kumaran Govender
                  </h1>
                <p className='people-1-para'>
                Snr Scientist/Operations Manager
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://gigengineer.io/profile/6628cb2544e3e15b39413845">
              <div className='gig-peoples-1-container'>
                <img src={Engineer11} alt="Engineer11" fontSize="medium" width="99%" />
                  <h1 className='people-1-name'>
                  Alan Sarkis
                  </h1>
                <p className='people-1-para'>
                Senior Process Engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://gigengineer.io/profile/malatsitilda@gmail.com">
              <div className='gig-peoples-1-container'>
                <img src={Engineer12} alt="Engineer12" fontSize="medium" width="95%" />
                  <h1 className='people-1-name'>
                  Tilda Malatsi
                  </h1>
                <p className='people-1-para'>
                Junior Chemical engineer
                </p>
              </div>
              </a>
              <a style={{ textDecoration: 'none' }} target="_blank" className='email-link-1' href="https://gigengineer.io/profile/Moruakgomo">
              <div className='gig-peoples-1-container'>
                <img src={Engineer13} alt="Engineer13" fontSize="medium" width="99%" />
                  <h1 className='people-1-name'>
                  Kagiso Molebaloa
                  </h1>
                <p className='people-1-para'>
                Quality Engineer
                </p>
              </div>
              </a>
            </Slider>
          </div>
        </div>

        <div className='testimonials-container'>
          <h1 className='testimonials-heading'>
            Testimonials
          </h1>
          <div className='testimonials-card-container' style={{paddingLeft: '20px', paddingRight: '20px'}}>
            <div className="slider-container">
              <link
                rel="stylesheet"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
              />
              <Slider {...settingsTest}>
                <div className='testimonials-card-1'>
                  <p className='testimonials-card-1-text'>
                  As a forward looking, progressive construction company, Citra is proud to partner with Gig Engineer to support us with our growing engineering needs.
                  </p>
                  <div className='testimo-line-break'></div>
                  <div className='main-container-testimo-1'>
                      <img src={Terminal1} alt="Terminal1" fontSize="medium" width="18%" />
                    <div>
                      <h1 className='testimonials-card-1-heading'>
                      Joel Baur
                      </h1>
                      <p className='p-Senior-engineer' style={{paddingRight: '20px'}}>
                        CEO and Co-Founder, Citra
                      </p>
                    </div>
                  </div>
                </div>
                <div className='testimonials-card-1'>
                  <p className='testimonials-card-1-text'>
                  Unity Power Solutions looks forward to building a long-term relationship with Gig Engineer. They’re tackling a significant problem by providing access to engineering opportunities across the value chain.
                  </p>
                  <div className='testimo-line-break'></div>
                  <div className='main-container-testimo-1'>
                      <img style={{borderRadius: '50px'}} src={ShawnImg} alt="shawnImg" fontSize="medium" width="18%" height="14%" />
                    <div>
                      <h1 className='testimonials-card-1-heading'>
                      Shawn Ramsunkar
                      </h1>
                      <p className='p-Senior-engineer' style={{paddingRight: '20px'}}>
                      Managing Director - Unity Power Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className='testimonials-card-1'>
                  <p className='testimonials-card-1-text'>
                  Gig Engineer helped me to find an excellent opportunity as a Resident Engineer at an international consulting company involved in innovative water projects in Africa. It's great working with the Gig Engineer team and getting access to such opportunities.
                  </p>
                  <div className='testimo-line-break'></div>
                  <div className='main-container-testimo-1'>
                      <img style={{borderRadius: '50px'}} src={ChikeImg} alt="chikeImg" fontSize="medium" width="17%" height="15%" />
                    <div>
                      <h1 className='testimonials-card-1-heading'>
                      Chike Okeke
                      </h1>
                      <p className='p-Senior-engineer' style={{paddingRight: '20px'}}>
                      Resident Engineer - Rundu Project (Namibia)
                      </p>
                    </div>
                  </div>
                </div>
                <div className='testimonials-card-1'>
                  <p className='testimonials-card-1-text'>
                  Gig Engineer has helped us with engineering and technical support as part of our energy saving and sustainability plans. They have been flexible to accommodate our specific needs and it's been a pleasure working with them.
                  </p>
                  <div className='testimo-line-break'></div>
                  <div className='main-container-testimo-1'>
                      <img style={{borderRadius: '50px'}} src={SeelanImg} alt="SeelanImg" fontSize="medium" width="17%" height="15%" />
                    <div>
                      <h1 className='testimonials-card-1-heading'>
                      Seelan Naidoo
                      </h1>
                      <p className='p-Senior-engineer' style={{paddingRight: '20px'}}>
                      Chief Engineer - Hilton Garden Inn, Umhlanga Arch
                      </p>
                    </div>
                  </div>
                </div>
                <div className='testimonials-card-1'>
                  <p className='testimonials-card-1-text'>
                  Gig Engineer is disrupting the traditional way engineering projects are resourced, and TCRPV Architects looks forward to partnering with them on this journey.
                  </p>
                  <div className='testimo-line-break'></div>
                  <div className='main-container-testimo-1'>
                      <img style={{borderRadius: '50px'}} src={RashmaImg} alt="rashmaImg" fontSize="medium" width="17%" height="15%" />
                    <div>
                      <h1 className='testimonials-card-1-heading'>
                      Rashma Patel
                      </h1>
                      <p className='p-Senior-engineer' style={{paddingRight: '20px'}}>
                       Director - TCRPV Architects
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

        </div>

        <div className='sign-up-and-connected-container'>
          <div className='class-image-signup'>
            <img className='signup-img-homepage-1' src={SignUpImage1} alt="SignUpImage1" fontSize="medium" width="100%" />
            <img className='signup-img-homepage-6' src={SignUpImage} alt="SignUpImage" fontSize="medium" width="100%" />
          </div>
          <div className='start-a-project-homepage'>
            <h1 className='Sign-up-as-a-business-heading'>
              Sign up as a business
            </h1>
            <div className='para-btn-project-page'>
              <p className='Sign-up-as-a-business-para'>
                Ready to join the revolution in engineering collaboration? Sign up today and start exploring
                the best engineering gigs and talent from around the world. With Gig Engineer, you'll have
                the tools to build your personal brand, manage your projects, and connect with other
                passionate professionals. Let's create something amazing together – sign up now!
              </p>
              <Button
                variant="contained"
                className="start-project-btn-homepage"
                type="submit"
                sx={{
                  textTransform: 'none',
                  width: '120px',
                  height: '40px',
                  boxShadow: 'none',
                  backgroundColor: '#003FAA'
                }}
                onClick={businessModal}
              >
                Start a Project
              </Button>
            </div>
          </div>
          <h1 className='get-connected-text' id='connected-text'>
            Get Connected
          </h1>
          <div className='get-connected-container'>
            <div className='get-connected-input'>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <span className='first-name-text-home-page'>First name*</span>
                  <TextField
                    autoComplete="true"
                    name="firstName"
                    required
                    fullWidth
                    type="text"
                    autoFocus
                    value={first}
                    onChange={(e) => {
                      e.preventDefault();
                      setFirst(e.target.value);
                      setFirstMsg("");
                    }
                    }
                    error={firstMsg !== ""}
                    helperText={firstMsg !== "" ? firstMsg : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <span className='first-name-text-home-page'>Last name*</span>
                  <TextField
                    required
                    fullWidth
                    name="lastName"
                    type="text"
                    autoComplete="true"
                    value={last}
                    onChange={(e) => {
                      e.preventDefault();
                      setLast(e.target.value);
                      setLastMsg("");
                    }
                    }
                    error={lastMsg !== ""}
                    helperText={lastMsg !== "" ? lastMsg : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <span className='first-name-text-home-page'>Email address*</span>
                  <TextField
                    required
                    fullWidth
                    name="email"
                    autoComplete="true"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      e.preventDefault();
                      setEmail(e.target.value);
                      setEmailMsg('');
                    }}
                    error={emailMsg !== ''}
                    helperText={emailMsg !== '' ? emailMsg : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <span className='first-name-text-home-page'>Your message*</span>
                  <TextField
                    required
                    fullWidth
                    name="query"
                    type="text"
                    autoComplete="new-password"
                    multiline
                    rows={3}
                    value={query}
                    onChange={(e) => {
                      e.preventDefault();
                      setQuery(e.target.value);
                      setQueryMsg("");
                    }
                    }
                    error={queryMsg !== ""}
                    helperText={queryMsg !== "" ? queryMsg : ""}
                  />
                </Grid>
              </Grid>

              <p className='contact-us-text-bottom'>
                By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and
                disclose your personal information including to third parties.
              </p>
              <Button
                variant="contained"
                className="get-in-touch-btn"
                type="submit"
                sx={{
                  textTransform: 'none',
                  width: '120px',
                  height: '40px',
                  boxShadow: 'none',
                  backgroundColor: '#003FAA'
                }}
                onClick={submitForm}
              >
                Get In Touch
              </Button>

            </div>
            <div className='mail-text-container'>
              <div className='main-icon-and-text'>
                <div>
                  <img src={MailIcon} alt="MailIcon" fontSize="medium" width="73%" />
                </div>
                <p className='email-us-text'>
                  Email us
                </p>
              </div>
              <h5 className='email-us-text-1'>
                Email us for general queries, including
                marketing and partnership opportunities.
              </h5>
              <a className='email-link-open' href="mailto:info@gigengineer.io">info@gigengineer.io</a>
            </div>
          </div>
        </div>

        <div className='faq-homepage-container' id='faq-redirect'>
          <div className='faq-text-1'>
            <h1 className='faq-heading-homepage'>
              FAQs
            </h1>
            <p className='faq-para-homepage'>
              Still curious?
            </p>
          </div>
          <div className='faq-text-2'>
            <div className="accordion">
              <div className="accordion-item">
                <div className="accordion-item-header">
                  What is Gig Engineer?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    Gig Engineer is a platform that companies with engineers on a full-time, fractional or gig work basis.
                  </div>
                </div>
              </div>
              <div className='break-line'></div>
              <div className="accordion-item">
                <div className="accordion-item-header">
                  How do I become a Gig Engineer?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    Becoming a Gig Engineer is easy. Signup in just a few clicks, create your profile, search and apply for opportunities that match what you're looking for or get contacted by companies directly via Gig Engineer. You're now on your way to becoming a successful Gig Engineer!
                  </div>
                </div>
              </div>
              <div className='break-line'></div>
              <div className="accordion-item">
                <div className="accordion-item-header">
                  How do I post a gig?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    To post a gig, sign in to your business account, navigate to the dashboard, and select 'Post a Gig.' Follow the steps to provide further details of the gig such as scope of work, required expertise, timeline, budget and any other relevant information, and click Post when you're done. Y`ou're gig is now live and able to receive applications.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-item-header">
                  How do I find the perfect engineer for my project?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    We provide several options to finding the right engineer for your project. 1) You can search directly for the type of engineer you want using advanced search words and filtering options to find the perfect candidate for your project. 2) You can post a gig to receive applicants and communicate directly with shortlisted candidates. 3) If you're too busy, you can contact us with your specific requirements and we'll find the perfect candidate for you.
                  </div>
                </div>
              </div>
              <div className='break-line'></div>
              <div className="accordion-item">
                <div className="accordion-item-header">
                  What are the fees?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    Gig Engineer is free to signup. We only charge a 10% fee based on the value of the project work executed and paid for via the platform which is deducted from the invoiced amount when the transaction is completed.
                  </div>
                </div>
              </div>
              <div className='break-line'></div>
              <div className="accordion-item">
                <div className="accordion-item-header">
                  How do I know if the engineer has been vetted?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    Engineers' profiles typically display a verification status. Look for the blue tick verification badge indicating an engineer's credentials have been reviewed by Gig Engineer. We also offer additional vetting and testing as required by our customers.
                  </div>
                </div>
              </div>
              <div className='break-line'></div>
              <div className="accordion-item">
                <div className="accordion-item-header">
                  Can I signup as a Business and an Engineer?
                </div>
                <div className="accordion-item-body">
                  <div className="accordion-item-body-content">
                    Yes, you can register as both a Business owner and an Engineer provided you meet the criteria and follow the terms of service for both roles.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='newletter-container'>
          <div className='newletter-text-para'>
            <h1 className='newletter-text'>
              Signup for Our Newsletter
            </h1>
            <p className='newletter-paragraph'>
              Join our newsletter to stay up-to-date on the latest engineering trends, new gig
              postings, and other exciting developments at Gig Engineer. We promise not to
              spam you or share your email address with anyone else. Subscribe now and
              become a part of our growing community of engineers and businesses!
            </p>

            <div className='input-or-sub-btn'>
              <TextField
                className='email-text-field-hpage'
                fullWidth
                id="email"
                name="email"
                value={emailG}
                placeholder="Type your email here"
                onChange={(e) => {
                  setEmailG(e.target.value);
                  setEmailMsgG('');
                }}
                error={emailMsgG !== ''}
                helperText={emailMsgG !== '' ? emailMsgG : ''}
              />

              <div className="button1">
                <Button className='subscribe-btn-homepage' onClick={submitFormM} variant="contained" color="info">
                  Subscribe
                </Button>
              </div>
            </div>
          </div>
          <div className='newletter-img'>
            <img src={Newimg} alt="Newimg" fontSize="medium" width="82%" />
          </div>
        </div>



        <FooterAfterSignIn />
      </div>
      {open ? <FindGigPopup signInEngineerPopup={open} modalEngineerOpen={(e) => { setOpen(true) }} modalEngineerClose={(e) => { setOpen(false) }} modalEngineerSignUpOpen={(e) => { setOpenSignUp(true) }} modalForgetEngineer={(e) => { setOpenForgetEngineer(true) }} modalEngineerSignUpClose={(e) => { setOpenSignUp(false) }} modalForgetClose={(e) => { setOpenForgetEngineer(false) }} /> : <></>}

      {openSignUp ? <FindGigPopup signUpModalOpen={openSignUp} modalEngineerSignUpOpen={(e) => { setOpenSignUp(true) }} modalEngineerOpen={(e) => { setOpen(true) }} modalEngineerSignUpClose={(e) => { setOpenSignUp(false) }} modalEngineerClose={(e) => { setOpen(false) }} modalForgetClose={(e) => { setOpenForgetEngineer(false) }} /> : <></>}

      {openForgetEngineer ? <FindGigPopup forgetSignIn={openForgetEngineer} modalForgetEngineer={(e) => { setOpenForgetEngineer(true) }} modalEngineerSignUpOpen={(e) => { setOpenSignUp(true) }} modalForgetClose={(e) => { setOpenForgetEngineer(false) }} modalEngineerClose={(e) => { setOpen(false) }} modalEngineerSignUpClose={(e) => { setOpenSignUp(false) }} /> : <></>}


      {openSignUpModal ? <FindEngineerPopup signInBusinessPopup={openSignUpModal} modalBusinessOpen={(e) => { setOpenSignUpModal(true) }} modalBusinessClose={(e) => { setOpenSignUpModal(false) }} businessModal={(e) => { setOpenBusinessModal(true) }} ForgetBusinessModalOpen={(e) => { setopenForgetBusiness(true) }} modalBusinessSignUpClose={(e) => { setOpenBusinessModal(false) }} modalBusinessForgetClose={(e) => { setopenForgetBusiness(false) }} /> : <></>}

      {openBusinessModal ? <FindEngineerPopup signUpBusinessModal={openBusinessModal} businessModal={(e) => { setOpenBusinessModal(true) }} modalBusinessOpen={(e) => { setOpenSignUpModal(true) }} modalBusinessSignUpClose={(e) => { setOpenBusinessModal(false) }} modalBusinessClose={(e) => { setOpenSignUpModal(false) }} modalBusinessForgetClose={(e) => { setopenForgetBusiness(false) }} /> : <></>}

      {openForgetBusiness ? <FindEngineerPopup forgetBusinessModal={openForgetBusiness} ForgetBusinessModalOpen={(e) => { setopenForgetBusiness(true) }} modalBusinessOpen={(e) => { setOpenSignUpModal(true) }} businessModal={(e) => { setOpenBusinessModal(true) }} modalBusinessForgetClose={(e) => { setopenForgetBusiness(false) }} modalBusinessClose={(e) => { setOpenSignUpModal(false) }} modalBusinessSignUpClose={(e) => { setOpenBusinessModal(false) }} /> : <></>}
    </React.Fragment >
  );
};

export default HomePage;