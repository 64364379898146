import React from 'react';
import HeaderWithLogo from '../../components/HeaderWithLogo/HeaderWithLogo';
import SignInForm from '../../components/SignInForm/SignInForm';
import WorkingBlog from '../../assests/workingBlog.png';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useHistory, useLocation } from 'react-router-dom';
import SignInSuccessMsg from '../../components/SignInSuccessMsg/SignInSuccessMsg'; 

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Gig Engineer
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password')
    });
  };

  const his = useHistory();
  const location = useLocation();

  return (
    <>
      {
        location.pathname.includes('verified') && <SignInSuccessMsg />
      }

      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{ display: { sm: 'none', xs: 'none', md: 'flex' }, alignItems: "center", justifyContent: "center" }}
          >
            <img src={WorkingBlog} alt="" width="70%" />
          </Grid>
          <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 5,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <HeaderWithLogo />
              <Typography
                sx={{
                  mt: 2,
                  color: '#5E5E5E',
                }}
                component="h1" variant="h6">
                Sign in
              </Typography>
              <SignInForm />
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                  <ListItem disablePadding>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: '#5E5E5E',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                          onClick={() => { his.push(`/termsconditions`); }}
                        >
                          <Link href="" underline="hover">
                            Terms and Conditions
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: '#5E5E5E',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                          onClick={() => { his.push(`/privacypolicy`); }}
                        >
                          <Link href="" underline="hover">
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
              <Box sx={{ mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <Copyright />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

